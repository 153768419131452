import React, { memo, useEffect } from "react";

import { Container, Row } from "react-bootstrap";
import { m } from "framer-motion";

import PriceCalculator from "../../Pages/PriceCalculator";
import VideoPriceCalculator from "../../Pages/PriceCalculator/videoPriceCalculator";
import DoorLockPriceCalculator from "../../Pages/PriceCalculator/doorLockPriceCalculator";
import Checkout from "../Checkout_Price_Calc/Checkout";
const PriceCalculatorEmbeded = (props) => {
  const { className, inHero } = props;
  useEffect(() => {
    const targetElement = document.getElementById("priceCalculatorembedded");

    if (!targetElement) return;

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Fire the event when the element becomes visible
            if (window.dataLayer) {
              window.dataLayer.push({ event: "PR_Check_In" });
            } else {
              console.log("window.dataLayer not created");
            }

            observer.unobserve(entry.target); // Stop observing after triggering once
          }
        });
      },
      { threshold: 0.5 } // Adjust visibility threshold as needed
    );

    observer.observe(targetElement);

    return () => observer.disconnect(); // Cleanup when component unmounts
  }, []);
  console.log("props", props);
  let bg = "bg-darkblue-grey";
  if (props.inHero) {
    bg = "";
  }
  return (
    <m.section
      // grid-cols-[34%_66%]

      className={` ${bg} text-white grid  grid-price-calc-embeded  md:grid-cols-1`}
      style={{ fontSize: "normal" }}
      id="priceCalculatorembedded"
    >
      <Container>
        {/* <div className="p-24 priceCalcEmbeded"> */}
        {/* <Row> */}
        {!inHero && (
          <div className=" priceCalcEmbeded">
            <h4>
              {props.h1
                ? props.h1
                : " Lassen Sie uns gemeinsam für Ihre Sicherheit sorgen."}
            </h4>
            <h5 className="subheader text-white">
              {" "}
              {props.h2
                ? props.h2
                : "Starten Sie jetzt Ihren Weg zu einem neuen Sicherheitsgefühl."}
            </h5>
            <p
              className="text-pink "
              style={{
                textDecoration: "",
                lineHeight: "175%",
                letterSpacing: "2px",
                fontWeight: "500",
                fontSize: "1.55rem",
              }}
            >
              {props.h3
                ? props.h3
                : "Jetzt Preisberechnung für eine Alarmanlage hier starten!"}
            </p>
          </div>
        )}

        {/* </Row> */}
      </Container>
      <div className="p-2  float-right">
        {props.type == "intrusion" ? (
          <PriceCalculator
            embeded={true}
            inHero={inHero}
            shaddow={true}
            className={className}
            key="calculator2"
          />
        ) : (
          ""
        )}
        {props.type == "video" ? (
          <VideoPriceCalculator embeded={true} inHero={inHero} shaddow={true} />
        ) : (
          ""
        )}
        {props.type == "door_lock" ? (
          <DoorLockPriceCalculator
            embeded={true}
            inHero={inHero}
            shaddow={true}
          />
        ) : (
          ""
        )}
        {props.type == "kunden_wer_kunden" ? (
          <Checkout
            technicalSource="Contact Form"
            marketingSource="Customers-recruit-Customers"
          />
        ) : (
          ""
        )}
        {props.type == "Kooperationspartner" ? (
          <Checkout
            technicalSource="Contact Form"
            marketingSource="Lead-Partner"
          />
        ) : (
          ""
        )}
      </div>
    </m.section>
  );
};

export default memo(PriceCalculatorEmbeded);
