import React, { Suspense, useEffect, useState, lazy } from "react";
// import TagManager from "react-gtm-module";

import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import TagManager from "react-gtm-module";

// Context
import GlobalContext from "./Context/Context";

import ScrollToTopButton from "./Components/ScrollToTop";
import Kundenwerbenkunden from "./Pages/Kunden-werben-kunden/kunden-werben-kunden";

const RouterOutlet = lazy(() => import("./Pages/RouterOutlet"));
const HomePage = lazy(() => import("./Pages/Home"));
const PriceCaclulator = lazy(() => import("./Pages/PriceCalculator"));
const AlarmanlagenPage = lazy(() => import("./Pages/Alarmanlagen"));
const AlarmanlagenHausPage = lazy(() => import("./Pages/Alarmanlagen-haus"));
const AlarmanlagenGewerbePage = lazy(() =>
  import("./Pages/Alarmanlagen-gewerbe")
);
const VideoSurveillance = lazy(() =>
  import("./Pages/Blog/BlogDetails/video-surveillance")
);
const KarrierePage = lazy(() => import("./Pages/karriere/karriere"));
const UeberUnsPage = lazy(() => import("./Pages/Ueber-uns/Ueber-uns"));
const Erklaervideo = lazy(() => import("./Pages/Erklaervideo/erklaervideo"));
const NotFoundPage = lazy(() => import("./Pages/404"));

const Beratungstermine = lazy(() =>
  import("./Pages/Beratungstermine/beratungstermine")
);
const RatgeberPage = lazy(() => import("./Pages/Blog/blogList"));
const AlarmanlagenTest2018 = lazy(() =>
  import("./Pages/Blog/BlogDetails/alarmanlagen-test-2018")
);
const Videoueberwachung = lazy(() =>
  import("./Pages/Videoueberwachung/videoueberwachung")
);
const AlarmanlageHausRatgeber = lazy(() =>
  import("./Pages/Blog/BlogDetails/alarmanlage-haus-ratgeber")
);
const AlarmanlageKaufen2020 = lazy(() =>
  import("./Pages/Blog/BlogDetails/alarmanlage-kaufen-2020")
);
const FunkAlarmanlagenVorteile = lazy(() =>
  import("./Pages/Blog/BlogDetails/funk-alarmanlagen-vorteile")
);
const ThermalkameraRatgeber = lazy(() =>
  import("./Pages/Blog/BlogDetails/thermalkamera-ratgeber")
);
const EinbruchschutzBeimNeubau = lazy(() =>
  import("./Pages/Blog/BlogDetails/einbruchschutz-beim-neubau")
);
const WasSollteManNachEinemEinbruchTun = lazy(() =>
  import("./Pages/Blog/BlogDetails/was-sollte-man-nach-einem-einbruch-tun")
);
const VideoPriceCalculator = lazy(() =>
  import("./Pages/PriceCalculator/videoPriceCalculator")
);
const Einbruchsatlas = lazy(() => import("./Pages/Einbruchsatlas/index"));
const EinbruchsatlasCheckout = lazy(() =>
  import("./Pages/Einbruchsatlas/EinbruchsatlasCheckout")
);
const Schließanlagen = lazy(() =>
  import("./Pages/Schließanlagen/Schließanlagen")
);
const Brandmeldeanlagen = lazy(() =>
  import("./Pages/Brandmeldeanlagen/brandmeldeanlagen")
);
const FieberKameras = lazy(() =>
  import("./Pages/Fieber-kameras/fieber-kameras")
);
const Tuerschloesser = lazy(() =>
  import("./Pages/Tuerschloesser/tuerschloesser")
);
const Einlasskontrolle = lazy(() =>
  import("./Pages/Einlasskontrolle/einlasskontrolle")
);
const AlarmanlagenEinzelhandel = lazy(() =>
  import("./Pages/Business_Langing_Pages/alarmanlagen-einzelhandel")
);
const AlarmanlagenArztpraxis = lazy(() =>
  import("./Pages/Business_Langing_Pages/alarmanlagen-arztpraxis")
);
const AlarmanlagenAutoUndKfz = lazy(() =>
  import("./Pages/Business_Langing_Pages/alarmanlagen-auto-und-kfz")
);
const AlarmanlagenBuero = lazy(() =>
  import("./Pages/Business_Langing_Pages/alarmanlagen-buero")
);
const AlarmanlagenLager = lazy(() =>
  import("./Pages/Business_Langing_Pages/alarmanlagen-lager")
);
const AlarmanlagenProduktionsstaette = lazy(() =>
  import("./Pages/Business_Langing_Pages/alarmanlagen-produktionsstaette")
);
const AGB = lazy(() => import("./Pages/LegaPages/agb"));
const Datenschutz = lazy(() => import("./Pages/LegaPages/datenschutz"));
const Impressum = lazy(() => import("./Pages/LegaPages/impressum"));
const Kooperationspartner = lazy(() =>
  import("./Pages/kooperationspartner/kooperationspartner")
);
const DoorLockPriceCalculator = lazy(() =>
  import("./Pages/PriceCalculator/doorLockPriceCalculator")
);
const Stellenanzeigen = lazy(() =>
  import("./Pages/Stellenanzeigen/stellenanzeigen")
);

const AlarmsySteme = lazy(() => import("./Pages/AlarmsySteme"));
const Nebelkanonen = lazy(() => import("./Pages/Nebelkanonen/nebelkanonen"));

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false,
  });
  const location = useLocation();

  function storeQueryParams() {
    const params = new URLSearchParams(window.location.search);
    let queryObject = {};

    params.forEach((value, key) => {
      queryObject[key] = value;
    });

    if (Object.keys(queryObject).length > 0) {
      sessionStorage.setItem("queryParams", JSON.stringify(queryObject));
    }
    // else {
    //   sessionStorage.removeItem("queryParams");
    // }
  }

  useEffect(() => {
    storeQueryParams();
  }, []);

  useEffect(() => {
    const gtmId = process.env.REACT_APP_GTM_ID;
    console.log("process.env iss", process.env);

    TagManager.initialize({ gtmId });
    setTimeout(() => {
      import("./Functions/Utilities").then((module) => {
        module.SetHeaderMenuPos();
        module.setDocumentFullHeight();
      });
    }, 1000);
  }, [location]);

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div
        className="container-custom "
        style={{ "--header-height": `${headerHeight}px` }}
      >
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense
                fallback={
                  <div class="loader-container">
                    <div class="lds-ripple">
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                }
              >
                <Routes>
                  <Route path="/" element={<RouterOutlet />}>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/preisrechner" element={<PriceCaclulator />} />
                    <Route
                      path="/alarmanlagen/preisrechner"
                      element={<PriceCaclulator />}
                    />
                    <Route
                      path="/hamburg/schliessanlagen/preisrechner/"
                      element={<DoorLockPriceCalculator />}
                    />
                    <Route
                      path="/hamburg/videoueberwachung/preisrechner"
                      element={<VideoPriceCalculator />}
                    />
                    <Route
                      path="/hamburg/alarmanlagen/preisrechner"
                      element={<PriceCaclulator />}
                    />
                    <Route
                      path="/hamburg/preisrechner"
                      element={<PriceCaclulator />}
                    />
                    <Route
                      path="/berlin/schliessanlagen/preisrechner/"
                      element={<DoorLockPriceCalculator />}
                    />
                    <Route
                      path="/berlin/videoueberwachung/preisrechner"
                      element={<VideoPriceCalculator />}
                    />
                    <Route
                      path="/berlin/alarmanlagen/preisrechner"
                      element={<PriceCaclulator />}
                    />
                    <Route
                      path="/berlin/preisrechner"
                      element={<PriceCaclulator />}
                    />
                    <Route
                      path="/nordrhein-westfalen/schliessanlagen/preisrechner/"
                      element={<DoorLockPriceCalculator />}
                    />
                    <Route
                      path="/nordrhein-westfalen/videoueberwachung/preisrechner"
                      element={<VideoPriceCalculator />}
                    />
                    <Route
                      path="/nordrhein-westfalen/alarmanlagen/preisrechner"
                      element={<PriceCaclulator />}
                    />
                    <Route
                      path="/nordrhein-westfalen/preisrechner"
                      element={<PriceCaclulator />}
                    />
                    <Route
                      path="/alarmanlagen"
                      element={<AlarmanlagenPage />}
                    />
                    <Route
                      path="/alarmanlagen-haus"
                      element={<AlarmanlagenHausPage />}
                    />
                    <Route
                      path="/alarmanlagen-gewerbe"
                      element={<AlarmanlagenGewerbePage />}
                    />
                    <Route path="/karriere" element={<KarrierePage />} />
                    <Route path="/ueber-uns" element={<UeberUnsPage />} />
                    <Route
                      path="/beratungstermine"
                      element={<Beratungstermine />}
                      // showFooter={false}
                    />
                    <Route path="/erklaervideo" element={<Erklaervideo />} />
                    <Route
                      path="/kunden-werben-kunden"
                      element={<Kundenwerbenkunden />}
                    />
                    <Route path="/ratgeber" element={<RatgeberPage />} />
                    <Route
                      path="/alarmanlagen/alarmanlagen-test-2018"
                      element={<AlarmanlagenTest2018 />}
                    />
                    <Route
                      path="/videoueberwachung"
                      element={<Videoueberwachung />}
                    />
                    <Route
                      path="/alarmanlagen/alarmanlage-haus-ratgeber"
                      element={<AlarmanlageHausRatgeber />}
                    />
                    <Route
                      path="/alarmanlagen/alarmanlage-kaufen-2020"
                      element={<AlarmanlageKaufen2020 />}
                    />
                    <Route
                      path="/alarmanlagen/funk-alarmanlagen-vorteile"
                      element={<FunkAlarmanlagenVorteile />}
                    />
                    <Route
                      path="/video/thermalkamera-ratgeber"
                      element={<ThermalkameraRatgeber />}
                    />
                    <Route
                      path="/ratgeber/einbruchschutz-beim-neubau"
                      element={<EinbruchschutzBeimNeubau />}
                    />
                    <Route
                      path="/ratgeber/was-sollte-man-nach-einem-einbruch-tun"
                      element={<WasSollteManNachEinemEinbruchTun />}
                    />
                    <Route
                      path="/videoueberwachung/preisrechner"
                      element={<VideoPriceCalculator />}
                    />
                    <Route
                      path="/schliessanlagen/preisrechner/"
                      element={<DoorLockPriceCalculator />}
                    />
                    <Route
                      path="/einbruchsatlas"
                      element={<Einbruchsatlas />}
                    />
                    <Route
                      path="/risikoeinschaetzung"
                      element={<EinbruchsatlasCheckout />}
                    />
                    <Route
                      // path="/Schließanlagen"
                      path="/schliessanlagen"
                      element={<Schließanlagen />}
                    />
                    <Route
                      path="/brandmeldeanlagen"
                      element={<Brandmeldeanlagen />}
                    />
                    <Route path="/fieber-kameras" element={<FieberKameras />} />
                    <Route
                      path="/tuerschloesser"
                      element={<Tuerschloesser />}
                    />
                    <Route
                      path="/einlasskontrolle"
                      element={<Einlasskontrolle />}
                    />
                    <Route path="/agb" element={<AGB />} />
                    <Route path="/datenschutz" element={<Datenschutz />} />
                    <Route path="/impressum" element={<Impressum />} />
                    <Route
                      path="/alarmanlagen-gewerbe/alarmanlagen-einzelhandel"
                      element={<AlarmanlagenEinzelhandel />}
                    />
                    <Route
                      path="/ratgeber/videoueberwachung"
                      element={<VideoSurveillance />}
                    />
                    <Route
                      path="/alarmanlagen-gewerbe/alarmanlagen-arztpraxis"
                      element={<AlarmanlagenArztpraxis />}
                    />
                    <Route
                      path="/alarmanlagen-gewerbe/alarmanlagen-auto-und-kfz"
                      element={<AlarmanlagenAutoUndKfz />}
                    />
                    <Route
                      path="/alarmanlagen-gewerbe/alarmanlagen-buero"
                      element={<AlarmanlagenBuero />}
                    />
                    <Route
                      path="/alarmanlagen-gewerbe/alarmanlagen-lager"
                      element={<AlarmanlagenLager />}
                    />
                    <Route
                      path="/alarmanlagen-gewerbe/alarmanlagen-produktionsstaette"
                      element={<AlarmanlagenProduktionsstaette />}
                    />

                    <Route
                      path="/kooperationspartner"
                      element={<Kooperationspartner />}
                    />
                    <Route
                      path="/stellenanzeigen"
                      element={<Stellenanzeigen />}
                    />
                    <Route
                      path=":page/videoueberwachung"
                      element={<Videoueberwachung />}
                    />
                    <Route
                      path=":page/alarmanlagen"
                      element={<AlarmanlagenPage />}
                    />
                    <Route
                      path=":page/schliessanlagen"
                      element={<Schließanlagen />}
                    />
                    <Route
                      path=":page/alarmanlagen-gewerbe"
                      element={<AlarmanlagenGewerbePage />}
                    />
                    <Route
                      path="/alarmanlagen-firma"
                      element={<AlarmanlagenGewerbePage />}
                    />
                    <Route path="/alarmsysteme" element={<AlarmsySteme />} />
                    <Route path="/nebelkanonen" element={<Nebelkanonen />} />
                    <Route
                      path=":page/alarmanlagen-haus"
                      element={<AlarmanlagenHausPage />}
                    />
                    <Route path=":page/" element={<HomePage />} />
                    <Route path="*" element={<NotFoundPage />} />
                  </Route>
                </Routes>
              </Suspense>
            </AnimatePresence>
          </main>
        }
      </div>
    </GlobalContext.Provider>
  );
}

export default App;
