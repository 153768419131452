import React, { useEffect, useState, useRef } from "react";

import { m } from "framer-motion";
import { Helmet } from "react-helmet";
import TagManager from "react-gtm-module";
// import { LazyLoadImage } from "react-lazy-load-image-component";

import Buttons from "@Components/Button/Buttons";
import { fadeInPC, fadeInLeftPC } from "../../Functions/GlobalAnimations";
import TrustAusgezeichnet from "../../Assets/img/Homepage/PC3-Trust-Ausgezeichnet-Homepage.webp";
import TrustMeisterbetrieb from "../../Assets/img/Homepage/PC2-Trust-Meisterbetrieb-Homepage.webp";
import TrustGoogle from "../../Assets/img/Homepage/PC1-Trust-Google-Homepage.webp";
// Properties Images
import IMG_HAUS from "../../Assets/img/price_calc/001 Haus.svg";
import IMG_APPARTMENT from "../../Assets/img/price_calc/001 Wohnung.svg";
import IMG_BUSINESS from "../../Assets/img/price_calc/001 Gewerbe.svg";
import IMG_OFFICE from "../../Assets/img/price_calc/001 Buero.svg";
// How many floor images
import HOW_MANY_FLOORS1 from "../../Assets/img/price_calc/103 1 Etage.svg";
import HOW_MANY_FLOORS2 from "../../Assets/img/price_calc/103 2 Etagen.svg";
import HOW_MANY_FLOORS3 from "../../Assets/img/price_calc/103 3 Etagen oder mehr.svg";
// Pets images
import PETS1 from "../../Assets/img/price_calc/105 Tiere.svg";
// Extentions images
import EXTENTIONS1 from "../../Assets/img/price_calc/002 Videoanlage.svg";
import EXTENTIONS2 from "../../Assets/img/price_calc/582 Elektronisches Türschloss.svg";
import EXTENTIONS3 from "../../Assets/img/price_calc/581 Brandmelder.svg";

import PANIC_BUTTON from "../../Assets/img/price_calc/107 Panic Button.svg";
import QUESTION_MARK from "../../Assets/img/price_calc/000 Weiss nicht.svg";

import NO_CROSS from "../../Assets/img/price_calc/000 Nein.svg";
import SMARTPHONE_VIDEO from "../../Assets/img/price_calc/208 Smartphone Video.svg";
import CHECKOUT1 from "../../Assets/img/price_calc/210527 Screenshot Sicherheitskonzept.webp";
import CHECKOUT2 from "../../Assets/img/price_calc/220322_DIN_Logo.webp";
import THANK_YOU from "../../Assets/img/price_calc/image_2_kostenlose_beratung.svg";
import Ausgezeichnet from "../../Assets/img/Homepage/01-Big-Hero-Trust-Ausgezeichnet-Homepage.webp";

import Button from "react-bootstrap/Button";
// import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
// import Row from "react-bootstrap/Row";

const API_URL = process.env.REACT_APP_API_URL;
// const PC_DATA = {
//   properties: {
//     name: "objectType",
//     question: "Which property do you want to secure?",
//     options: [
//       // Haus,Wohnung,Gewerbe,Buero
//       {
//         name: "House",
//         value: "Haus",
//         img: IMG_HAUS,
//       },
//       { name: "Appartment", value: "Wohnung", img: IMG_APPARTMENT },
//       { name: "Business", value: "Gewerbe", img: IMG_BUSINESS },
//       { name: "Office", value: "Buero", img: IMG_OFFICE },
//     ],
//   },
//   HowManyFloors: {
//     name: "floors",
//     question: "How many floors does your house have?",
//     options: [
//       { name: "1 floor", value: "1", img: HOW_MANY_FLOORS1 },
//       {
//         name: "2 floor",
//         value: "2",

//         img: HOW_MANY_FLOORS2,
//       },
//       {
//         name: "3 or more",
//         value: "3",

//         img: HOW_MANY_FLOORS3,
//       },
//     ],
//   },
//   HowManyFloorsAppartment: {
//     name: "floors",
//     question: "Which floor is your apartment on?",
//     options: [
//       { name: "1 floor", value: "1", img: HOW_MANY_FLOORS1 },
//       {
//         name: "2 floor",
//         value: "2",

//         img: HOW_MANY_FLOORS2,
//       },
//       {
//         name: "3 or more",
//         value: "3",

//         img: HOW_MANY_FLOORS3,
//       },
//     ],
//   },
//   HowManyFloorsBusiness: {
//     name: "floors",
//     question: "How many floors does your business have?",
//     options: [
//       { name: "1 floor", value: "1", img: HOW_MANY_FLOORS1 },
//       {
//         name: "2 floor",
//         value: "2",

//         img: HOW_MANY_FLOORS2,
//       },
//       {
//         name: "3 or more",
//         value: "3",

//         img: HOW_MANY_FLOORS3,
//       },
//     ],
//   },
//   HowManyFloorsOffice: {
//     name: "floors",
//     question: "Which floor is your office on?",
//     options: [
//       { name: "1 floor", value: "1", img: HOW_MANY_FLOORS1 },
//       {
//         name: "2 floor",
//         value: "2",

//         img: HOW_MANY_FLOORS2,
//       },
//       {
//         name: "3 or more",
//         value: "3",

//         img: HOW_MANY_FLOORS3,
//       },
//     ],
//   },
//   WindowsAndExterior: {
//     name: "windows",
//     question: "How many windows and exterior doors does your home have?",
//   },
//   WindowsAndExteriorAppartment: {
//     name: "windows",
//     question: "How many windows and exterior doors does your apartment have?",
//   },
//   WindowsAndExteriorBusiness: {
//     name: "windows",
//     question: "How many windows and exterior doors does your business have?",
//   },
//   WindowsAndExteriorOffice: {
//     name: "windows",
//     question: "How many windows and exterior doors does your office have?",
//   },
//   Pets: {
//     name: "pets",
//     question: "Do you have pets that can move freely indoors?",
//     options: [
//       { name: "Yes", value: "Ja", img: PETS1 },
//       {
//         name: "No",
//         value: "Nein",
//         img: NO_CROSS,
//       },
//     ],
//   },
//   Bedroom: {
//     name: "panic_button",
//     question:
//       "Would you like to have a hold-up alarm switch installed (e.g. in the bedroom)?",
//     options: [
//       { name: "Yes", value: "Ja", img: PANIC_BUTTON },
//       { name: "No", value: "Nein", img: NO_CROSS },
//       { name: "I do not know", value: "Weiß ich nicht", img: QUESTION_MARK },
//     ],
//   },
//   AccessViaSmartPhone: {
//     name: "smartPhone",
//     question:
//       "Would you like to be able to access your alarm system via smartphone app?",
//     options: [
//       { name: "Yes", value: "Ja", img: SMARTPHONE_VIDEO },
//       { name: "No", value: "Nein", img: NO_CROSS },
//       { name: "I do not know", value: "Weiß ich nicht", img: QUESTION_MARK },
//     ],
//   },
//   Extentions: {
//     name: "crossSelling",
//     question: "Are you interested in the following extensions?",
//     options: [
//       { name: "Video system", value: "Video", img: EXTENTIONS1 },
//       {
//         name: "Electronic door locks",
//         value: "Schließanlage",
//         img: EXTENTIONS2,
//       },
//       { name: "Fire alarm system", value: "Brand", img: EXTENTIONS3 },
//     ],
//   },
// };
const PC_DATA = {
  properties: {
    name: "objectType",
    question: "Welche Immobilie möchten Sie sichern?",
    options: [
      // Haus,Wohnung,Gewerbe,Buero
      {
        name: "Haus",
        value: "Haus",
        img: IMG_HAUS,
        alt: "Alarmanlage Haus",
      },
      {
        name: "Wohnung",
        value: "Wohnung",
        img: IMG_APPARTMENT,
        alt: "Alarmanlage Wohnung",
      },
      {
        name: "Gewerbe",
        value: "Gewerbe",
        img: IMG_BUSINESS,
        alt: "Alarmanlage Gewerbe",
      },
      {
        name: "Büro",
        value: "Buero",
        img: IMG_OFFICE,
        alt: "Alarmanlage Büro",
      },
    ],
  },
  HowManyFloors: {
    name: "floors",
    question: "Wie viele Etagen hat Ihr Haus?",
    options: [
      { name: "1 Etage", value: "1", img: HOW_MANY_FLOORS1 },
      {
        name: "2 Etagen",
        value: "2",

        img: HOW_MANY_FLOORS2,
      },
      {
        name: "3 Etagen oder mehr",
        value: "3",

        img: HOW_MANY_FLOORS3,
      },
    ],
  },
  HowManyFloorsAppartment: {
    name: "floors",
    question: "In welcher Etage befindet sich Ihre Wohnung?",
    options: [
      { name: "Erdgeschoss", value: "1", img: HOW_MANY_FLOORS1 },
      {
        name: "1. Etage",
        value: "2",

        img: HOW_MANY_FLOORS2,
      },
      {
        name: "2. Etage oder höher",
        value: "3",

        img: HOW_MANY_FLOORS3,
      },
    ],
  },
  HowManyFloorsBusiness: {
    name: "floors",
    question: "Wie viele Etagen hat Ihr Gewerbe?",
    options: [
      { name: "1 Etage", value: "1", img: HOW_MANY_FLOORS1 },
      {
        name: "2 Etagen",
        value: "2",

        img: HOW_MANY_FLOORS2,
      },
      {
        name: "3 Etagen oder mehr",
        value: "3",

        img: HOW_MANY_FLOORS3,
      },
    ],
  },
  HowManyFloorsOffice: {
    name: "floors",
    question: "In welcher Etage befindet sich Ihr Büro?",
    options: [
      { name: "Erdgeschoss", value: "1", img: HOW_MANY_FLOORS1 },
      {
        name: "1. Etage",
        value: "2",

        img: HOW_MANY_FLOORS2,
      },
      {
        name: "2. Etage oder höher",
        value: "3",

        img: HOW_MANY_FLOORS3,
      },
    ],
  },
  WindowsAndExterior: {
    name: "windows",
    question: "Wie viele Fenster und Außentüren hat Ihr Haus?",
  },
  WindowsAndExteriorAppartment: {
    name: "windows",
    question: "Wie viele Fenster und Außentüren hat Ihre Wohnung?",
  },
  WindowsAndExteriorBusiness: {
    name: "windows",
    question: "Wie viele Fenster und Außentüren hat Ihr Gewerbe?",
  },
  WindowsAndExteriorOffice: {
    name: "windows",
    question: "Wie viele Fenster und Außentüren hat Ihr Büro?",
  },
  Pets: {
    name: "pets",
    question: "Haben Sie Haustiere, die sich frei im Innenraum bewegen können?",
    options: [
      { name: "Ja", value: "Ja", img: PETS1 },
      {
        name: "Nein",
        value: "Nein",
        img: NO_CROSS,
      },
    ],
  },
  Bedroom: {
    name: "panic_button",
    question:
      "Möchten Sie gerne einen Überfallalarmschalter installieren lassen (z.B. im Schlafzimmer)?",
    options: [
      { name: "Ja", value: "Ja", img: PANIC_BUTTON },
      { name: "Nein", value: "Nein", img: NO_CROSS },
      { name: "Weiß nicht", value: "Weiß ich nicht", img: QUESTION_MARK },
    ],
  },
  AccessViaSmartPhone: {
    name: "smartPhone",
    question:
      "Möchten Sie gerne per Smartphone App auf Ihre Alarmanlage zugreifen können?",
    options: [
      { name: "Ja", value: "Ja", img: SMARTPHONE_VIDEO },
      { name: "Nein", value: "Nein", img: NO_CROSS },
      { name: "Weiß nicht", value: "Weiß ich nicht", img: QUESTION_MARK },
    ],
  },
  Extentions: {
    name: "crossSelling",
    question: "Haben Sie Interesse an folgenden Erweiterungen?",
    questionInfo: "Mehrfachauswahl möglich",
    options: [
      { name: "Videoanlage", value: "Video", img: EXTENTIONS1 },
      {
        name: "Elektronische Türschlösser",
        value: "Schließanlage",
        img: EXTENTIONS2,
      },
      { name: "Brandmeldeanlage", value: "Brand", img: EXTENTIONS3 },
    ],
  },
};
// let slideNum.current = -1;
let phone = 0;
const PriceCalculator = (propsMain) => {
  const slideNum = useRef(-1); // Unique for each component instance

  let { embeded, className, shaddow, inHero } = propsMain;
  const isDesktop = typeof window !== "undefined" && window.innerWidth > 768;
  const fadeInLeftPC_X = isDesktop ? fadeInLeftPC : {};
  const fadeInPC_X = isDesktop ? fadeInPC : {};
  useEffect(() => {
    if (window.dataLayer && !embeded) {
      window.dataLayer.push({ event: "PR_Check_In" });
    } else {
      console.log("window.dataLayer not created");
    }
  }, []);

  const [Form, setForm] = useState({
    objectType: "Haus",
    floors: "",
    pets: "",
    bedroom: "",
    smartPhone: "",
    extentions: "",
    crossSelling: [],
  });
  const [Slide, setSlide] = useState(-1);
  const [Progress, setProgress] = useState(0);
  const [displayDonKnow, setDisplayDonKnow] = useState(false);
  const [isThankYou, setIsThankYou] = useState(false);
  const [isCheckout, setIsCheckout] = useState(false);
  const props = {
    handleInputChange,
    Form,
    setForm,
    nextPriceCard,
    animate: fadeInLeftPC_X,
  };
  const propsSelect = {
    handleSelectChange,
    Form,
    nextPriceCard,
    animate: fadeInLeftPC_X,
  };
  // const userFormprops = { User, setUser };
  const PC_FLOW = {
    Haus: [
      <HowManyFloorsList {...props} />,
      <WindowsAndExterior {...propsSelect} />,
      <Pets {...props} />,
      <Bedroom {...props} />,
      <AccessViaSmartPhone {...props} />,
      <Extentions {...props} />,
      <UserInfoForm {...props} />,
      <ThankYou />,
    ],

    Wohnung: [
      <HowManyFloorsListAppartment {...props} />,
      <WindowsAndExteriorAppartment {...propsSelect} />,
      <Pets {...props} />,
      <Bedroom {...props} />,
      <AccessViaSmartPhone {...props} />,
      <Extentions {...props} />,
      <UserInfoForm {...props} />,
      <ThankYou />,
    ],
    Gewerbe: [
      <HowManyFloorsListBusiness {...props} />,
      <WindowsAndExteriorBusiness {...propsSelect} />,
      // <Pets {...props} />,
      <AccessViaSmartPhone {...props} />,
      <Extentions {...props} />,
      <UserInfoForm {...props} />,
      <ThankYou />,
    ],
    Buero: [
      <HowManyFloorsListOffice {...props} />,
      <WindowsAndExteriorOffice {...propsSelect} />,
      <Pets {...props} />,
      <AccessViaSmartPhone {...props} />,
      <Extentions {...props} />,
      <UserInfoForm {...props} />,
      <ThankYou />,
    ],
  };

  const PriceCalculatorOutlet = () => {
    if (Slide === -1) {
      return <PropertiesList {...props} className="animate-blink" />;
    }
    const Flow = PC_FLOW[Form.objectType][Slide] ?? (
      <PropertiesList {...props} className="animate-blink" />
    );
    return <div className="animate-blink">{Flow}</div>;
  };

  function handleInputChange(FormData) {
    console.log("FormData", FormData);
    const { name, value } = FormData;
    setForm({ ...Form, ...{ [name]: value } });
    nextPriceCard();
    console.log("Form", Form);
  }
  function handleSelectChange(e) {
    console.log("event", e);
    const { name, value } = e.target;
    setForm({ ...Form, ...{ [name]: value } });
    console.log("Form", Form);
  }

  function trusSymbolClases() {
    // hidden={Slide === -1 || Progress == 100 ? true : false}
    let trustImgClasses =
      !PC_FLOW[Form.objectType].length - 2 == Slide ||
      !PC_FLOW[Form.objectType].length - 1 == Slide
        ? "trust-imgs"
        : " trust-imgs mt-48 mt-7rem";
    if (window.innerWidth >= 768) {
      trustImgClasses = "trust-imgs mt-48 mt-7rem";
    }
    if (Progress == 100) {
      trustImgClasses = "trust-imgs mt-12";
    }
    return trustImgClasses;
  }
  function displayIdontKnow() {
    if (
      ((Form.objectType == "Haus" || Form.objectType == "Wohnung") &&
        slideNum.current == 5) ||
      (Form.objectType == "Gewerbe" && slideNum.current == 3) ||
      (Form.objectType == "Buero" && slideNum.current == 4) ||
      slideNum.current == 1
    ) {
      setDisplayDonKnow(true);
    } else {
      setDisplayDonKnow(false);
    }
  }
  function isThankYouFun() {
    if (
      ((Form.objectType == "Haus" || Form.objectType == "Wohnung") &&
        slideNum.current == 6) ||
      (Form.objectType == "Gewerbe" && slideNum.current == 4) ||
      (Form.objectType == "Buero" && slideNum.current == 5)
    ) {
      setIsThankYou(true);
    } else {
      setIsThankYou(false);
    }
  }
  function isCheckoutFun() {
    if (
      ((Form.objectType == "Haus" || Form.objectType == "Wohnung") &&
        slideNum.current == 6) ||
      (Form.objectType == "Gewerbe" && slideNum.current == 6) ||
      (Form.objectType == "Buero" && slideNum.current == 6)
    ) {
      setIsCheckout(true);
    } else {
      setIsCheckout(false);
    }
  }

  function nextPriceCard(e) {
    if (!PC_FLOW[Form.objectType][Slide + 1]) {
      return;
    }
    slideNum.current = slideNum.current + 1;
    setSlide(Slide + 1);
    console.log("slide", Slide);
    console.log("slideNum.current", slideNum.current);

    displayIdontKnow();
    isThankYouFun();
    isCheckoutFun();
  }
  function prevPriceCard(e) {
    if (Slide === -1) {
      return;
    }
    slideNum.current = slideNum.current - 1;
    setSlide(Slide - 1);
    console.log("slide", Slide);
    console.log("slideNum.current", slideNum.current);

    displayIdontKnow();
  }
  function ProgressBarCom() {
    const ComponentsLength = Form.objectType
      ? PC_FLOW[Form.objectType].length
      : 10;
    // console.log("ComponentsLength", ComponentsLength);
    let now = (((Slide + 1) / (ComponentsLength - 1)) * 100).toFixed();
    // console.log("now", now);
    if (now == 0) {
      now = 7;
    }
    setProgress(now);
    return (
      <m.section>
        <div className="flex justify-between mb-1">
          <span></span>
          <span className="text-sm font-medium float-right text-dark-grey">
            {now}%
          </span>
        </div>
        {/* <div className="w-full  rounded-full h-2">
          <div
            className="bg-theme-blue h-2 rounded-full"
            style={{ width: `${now}%` }}
          ></div>
        </div> */}

        <div class="w-full bg-gray-200 rounded-full h-2 dark:bg-gray-200 float-left">
          <div
            class="bg-theme-blue h-2 rounded-full"
            style={{ width: `${now}%` }}
          ></div>
        </div>
      </m.section>
    );
  }
  useEffect(() => {
    return () => {
      slideNum.current = -1;
    };
  }, []);
  // let heroCalc = " full-screen hero-calc";
  let heroCalc = " hero-calc";
  let spacing = " ";
  if (inHero) {
    heroCalc = "";
    spacing = "pb-0";
  }
  const clases = "  overflow-hidden hero-header  " + heroCalc + className + " ";
  return (
    <div style={props.style}>
      {!embeded ? (
        <Helmet>
          <title>Jetzt Preis für Ihre Alarmanlage berechnen</title>
          <meta
            name="description"
            content="Was kostet eine Alarmanlage? Erhalten Sie in wenigen Minuten einen Preis für Alarmanlagen & Alarmsystem | Preis & Kosten Alarmanlage jetzt berechnen!"
          />
          <meta
            name="keywords"
            content="preis alarmanlage, alarmanlage preis, kosten alarmanlage, alarmanlage kosten, preis berechnen alarmanlage, was kostet eine alarmanlage"
          />
        </Helmet>
      ) : (
        ""
      )}

      <m.section
        className={embeded ? clases + " embeded-calc" : clases + " hero-image"}
        {...fadeInPC_X}
      >
        <img
          src={Ausgezeichnet}
          className="md:hidden block"
          style={{
            maxHeight: "60px",
            position: "absolute",
            right: "10px",
            top: "39px",
          }}
          alt="Ausgezeichnung für professionelle Sicherheitstechnik von MeinAlarm24"
        />
        {(!embeded && Progress != 100) ||
        (!embeded && Progress == 100 && window.innerHeight > 800) ? (
          <h4 className=" font-semibold  text-center text-[#fff] mt-10  sm:hidden block ">
            Ihr kostenloser Alarmanlagen-Preisrechner
          </h4>
        ) : (
          ""
        )}
        <div className=" h-full text-center justify-center ">
          <div
            className={
              shaddow
                ? " price-calculator box-shaddow-price-calc price-calculator-620-fixwidth  md:mx-auto " +
                  spacing
                : "price-calculator mx-auto box-shaddow-price-calc " + spacing
            }
          >
            {PC_FLOW[Form.objectType].length - 2 == Slide ? (
              <div
                class="flex items-center p-3  text-lg text-white  bg-[#74af53]  "
                role="alert"
              >
                <i class="fa-solid fa-check mr-2"></i>

                <div>
                  Gute Nachricht! Der Preis für Ihre Anlage wird ermittelt.
                </div>
              </div>
            ) : (
              ""
            )}
            {PC_FLOW[Form.objectType].length - 1 == Slide ? (
              <div
                class="flex items-center p-4 mb-4 text-lg text-white  bg-[#74af53] "
                role="alert"
              >
                {/* <i className="fa fa-angle-down mr-2" /> */}
                <i class="fa-solid fa-check"></i>
                <div> &nbsp;Herzlichen Dank für Ihre Preisanfrage!</div>
              </div>
            ) : (
              ""
            )}
            <div className="p-8 padding-399 padding-05">
              {Progress == 100 ? (
                ""
              ) : (
                <div className="progress-bar">{<ProgressBarCom />}</div>
              )}
              <div className="pc-questions fade-in-right" {...fadeInLeftPC_X}>
                {" "}
                {/* animation={fadeInLeftPC} */}
                {/* <AnimatePresence> */}
                <PriceCalculatorOutlet />
                {/* </AnimatePresence> */}
              </div>
              <div
                className="pc-footer mt-20 xs:!mt-4 block  mt-2rem "
                // hidden={Slide === -1 || Progress == 100 ? true : false}
              >
                {/* <div className="d-none d-md-block"> */}
                <button
                  type="button"
                  class="float-left button-left px-4 py-3.5 text-sm text-gray-700 "
                  onClick={prevPriceCard}
                  hidden={Slide === -1 || Progress == 100 ? true : false}
                >
                  <i class="fa fa-arrow-left"></i>
                  {/* <span> Back</span> */}
                </button>
                <div className="d-none d-md-block relative">
                  {Slide === -1 ? (
                    // <span className="hidden lgplus:block">
                    <span>
                      <div className="flex absolute left-0 items-center   p-2 bg-[#f2f2f2] rounded-md min-h-[50px]  max-w-423px">
                        <i
                          className="fas fa-piggy-bank text-[#1895ff] text-4xl"
                          style={{ minWidth: "2.5rem" }}
                        ></i>
                        <p className="text-[16px] leading-[1.7rem] m-0">
                          Jetzt noch für kurze Zeit:{" "}
                          <span className="font-bold">
                            -10% Frühjahrs-Rabatt
                          </span>{" "}
                          auf unsere Sicherheitstechnik
                        </p>
                      </div>{" "}
                    </span>
                  ) : (
                    "" // Empty to maintain grid layout
                  )}
                  <button
                    type="button"
                    class="px-14 py-2.5 text-white bg-[#1895ff] hover:bg-[#1486e9]   font-medium float-right "
                    onClick={nextPriceCard}
                    hidden={
                      PC_FLOW[Form.objectType].length - 2 == Slide ||
                      PC_FLOW[Form.objectType].length - 1 == Slide
                    }
                    disabled={!displayDonKnow}
                  >
                    Weiter
                  </button>
                  {displayDonKnow ? (
                    <button
                      type="button"
                      class="text-gray-900 bg-white border float-right border-gray-300   font-medium  text-sm px-8 py-3.5   ml-2 mr-3"
                      onClick={nextPriceCard}
                    >
                      Weiß nicht
                    </button>
                  ) : (
                    ""
                  )}
                </div>

                <div className="d-block d-md-none">
                  {/* <button
                    type="button"
                    class="float-left button-left px-4 py-3.5 text-sm text-gray-700 "
                    onClick={prevPriceCard}
                    hidden={!displayDonKnow}
                  > */}
                  {/* <i class="fa fa-arrow-left" ></i> */}
                  {/* <span> Back</span> */}
                  {/* </button> */}

                  <button
                    type="button"
                    class="px-16 py-2.5 text-white bg-[#1895ff] hover:bg-[#1486e9]   font-medium float-right "
                    onClick={nextPriceCard}
                    hidden={!displayDonKnow}
                  >
                    Weiter
                  </button>
                  {displayDonKnow ? (
                    <button
                      type="button"
                      class="text-gray-900 bg-white border float-right border-gray-300   font-medium  text-sm px-8 py-3.5   ml-2 mr-3"
                      onClick={nextPriceCard}
                    >
                      Weiß nicht
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className={trusSymbolClases()}>
                <h3
                  style={{
                    fontSize: 16,
                    marginBottom: "10px",
                    textAlign: "center",
                    padding: "0",
                  }}
                  className="d-block d-md-none"
                >
                  Ausgezeichnet durch:
                </h3>
                <div className="pc-footer-trust-symbols">
                  <h3
                    style={{
                      fontSize: 16,
                      marginBottom: 10,
                      textAlign: "center",
                      padding: "0",
                    }}
                    className="d-none d-md-block"
                  >
                    Ausgezeichnet <br />
                    durch:
                  </h3>

                  <img
                    src={TrustGoogle}
                    className="pc-footer-trust-symbols-img-1"
                    alt="Ausgezeichnete Alarmanlagen Google Rezensionen"
                  />
                  <img
                    className="pc-footer-trust-symbols-img-2"
                    src={TrustMeisterbetrieb}
                    alt="Alarmanlagen vom eingetragenen Meisterbetrieb"
                  />
                  <img
                    className="pc-footer-trust-symbols-img-3"
                    src={TrustAusgezeichnet}
                    alt="Hervorragende Alarmanlagen Ausgezeichnet.org Rezensionen"
                  />
                </div>
                {Slide == -1 ? (
                  // <span className="block lgplus:hidden">
                  <span>
                    <div
                      className="ml-4  p-2 mt-10 xs:!mt-4  d-block d-md-none "
                      style={{
                        backgroundColor: "rgb(242, 242, 242)",
                        padding: "0.9rem",
                        minHeight: "50px",
                        verticalAlign: "middle",
                        margin: "12px 0px",

                        borderRadius: "5px",
                      }}
                    >
                      <i
                        class="fas fa-piggy-bank   fa-piggy-custom"
                        style={{
                          color: "rgb(24, 149, 255)",
                          verticalAlign: "middle",
                          fontSize: "2.5rem",
                          // float: "left",
                        }}
                      ></i>
                      <p
                        style={{
                          lineHeight: "1.7rem !important",
                          fontSize: "16px",
                        }}
                      >
                        Jetzt noch für kurze Zeit:{" "}
                        <span className="font-bold">
                          {" "}
                          -10% Frühjahrs-Rabatt
                        </span>{" "}
                        auf unsere Sicherheitstechnik
                      </p>
                    </div>
                  </span>
                ) : (
                  ""
                )}
                {isThankYou ? (
                  <>
                    <div
                      className="ml-4  p-2 d-block d-md-none "
                      style={{
                        backgroundColor: "rgb(242, 242, 242)",
                        padding: "0.9rem",
                        minHeight: "50px",
                        verticalAlign: "middle",
                        margin: "12px 0px",
                        marginTop: "40px",
                        borderRadius: "5px",
                      }}
                    >
                      <i
                        class="fas fa-piggy-bank   fa-piggy-custom"
                        style={{
                          color: "rgb(24, 149, 255)",
                          verticalAlign: "middle",
                          fontSize: "2.5rem",
                          // float: "left",
                        }}
                      ></i>
                      <p
                        style={{
                          lineHeight: "1.7rem !important",
                          fontSize: "16px",
                        }}
                      >
                        Jetzt noch für kurze Zeit:{" "}
                        <span className="font-bold">
                          {" "}
                          -10% Frühjahrs-Rabatt
                        </span>{" "}
                        auf unsere Sicherheitstechnik
                      </p>
                    </div>
                    {/* <div className="grid grid-cols-2 d-block d-md-none mt-5">
                      <img  src={CHECKOUT1} style={{ maxWidth: "20rem" }} />
                      <img
                        src={CHECKOUT2}
                        className="mt-2 mb-2"
                        style={{ maxWidth: "20rem" }}
                      />
                    </div> */}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </m.section>
    </div>
  );
};

// function PropertiesList(props) {
//   const { handleInputChange, Form } = props;

//   return (
//     <m.section>
//       {/* // <m.section > */}
//       <h3>{PC_DATA.properties.question}</h3>
//       <div className="grid grid-cols-4  place-items-center place-content-center  sm:grid-cols-1 gap-4 sm:gap-2 ">
//         {PC_DATA.properties.options.map((option) => {
//           return (
//             <div
//               className={
//                 Form[PC_DATA.properties.name] === option.value
//                   ? " card  pc-card grow-animate pc-card-active"
//                   : "card  pc-card grow-animate "
//               }
//               key={option.name}
//               style={{ width: "100%" }}
//               onClick={() =>
//                 handleInputChange({
//                   name: PC_DATA.properties.name,
//                   value: option.value,
//                 })
//               }
//             >
//               <div className="card-body">
//                 {/* <span className="card-title"> */}
//                 <img
//                   src={option.img}
//                   className="price-calc-icon sm:float-left"
//                   alt={option.alt}
//                 />
//                 <span className="hidden sm:block sm:float-left sm:align-middle mt-[1.4rem] font-medium text-xlg ">
//                   {option.name}
//                 </span>
//                 {/* </span> */}
//               </div>
//               <div className="card-footer pc-caption block sm:hidden">
//                 {option.name}
//               </div>
//             </div>
//           );
//         })}
//       </div>
//     </m.section>
//   );
// }
const PropertiesList = React.memo(function PropertiesList(props) {
  const { handleInputChange, Form } = props;
  console.log("PropertiesList rendered............");

  return (
    <m.section>
      <h3>{PC_DATA.properties.question}</h3>
      <div className="grid grid-cols-4 place-items-center sm:grid-cols-1 gap-4 sm:gap-2">
        {PC_DATA.properties.options.map((option) => (
          <div
            key={option.name}
            className={`card pc-card grow-animate ${
              Form[PC_DATA.properties.name] === option.value
                ? "pc-card-active"
                : ""
            }`}
            style={{ width: "100%" }}
            onClick={() =>
              handleInputChange({
                name: PC_DATA.properties.name,
                value: option.value,
              })
            }
          >
            <div className="card-body">
              <img
                src={option.img}
                loading="lazy"
                className="price-calc-icon sm:float-left"
                alt={option.alt}
              />
              <span className="hidden sm:block sm:float-left sm:align-middle mt-[1.4rem] font-medium text-xlg">
                {option.name}
              </span>
            </div>
            <div className="card-footer pc-caption block sm:hidden">
              {option.name}
            </div>
          </div>
        ))}
      </div>
    </m.section>
  );
});

function HowManyFloorsList(props) {
  const { handleInputChange, Form, animate } = props;

  return (
    // <m.section {...animate}>
    <m.section>
      <h3>{PC_DATA.HowManyFloors.question}</h3>
      <div className="grid grid-cols-3     sm:grid-cols-1 gap-4 sm:gap-2 place-items-center place-content-center">
        {PC_DATA.HowManyFloors.options.map((option) => {
          return (
            <div
              className={
                Form[PC_DATA.HowManyFloors.name] === option.value
                  ? " card  pc-card grow-animate pc-card-active"
                  : "card  pc-card grow-animate "
              }
              key={option.name}
              style={{ width: "100%" }}
              onClick={() =>
                handleInputChange({
                  name: PC_DATA.HowManyFloors.name,
                  value: option.value,
                })
              }
            >
              <div className="card-body">
                {/* <span className="card-title"> */}
                <img
                  src={option.img}
                  className="price-calc-icon sm:float-left"
                />
                <span className="hidden sm:block sm:float-left sm:align-middle mt-[1.4rem] font-medium text-xlg ">
                  {option.name}
                </span>
                {/* </span> */}
              </div>
              <div className="card-footer pc-caption block sm:hidden">
                {option.name}
              </div>
            </div>
          );
        })}
      </div>
    </m.section>
  );
}
function HowManyFloorsListOffice(props) {
  const { handleInputChange, Form, animate } = props;

  return (
    <m.section {...animate}>
      <h3>{PC_DATA.HowManyFloorsOffice.question}</h3>
      <div className="grid grid-cols-3     sm:grid-cols-1 gap-4 sm:gap-2 place-items-center place-content-center">
        {PC_DATA.HowManyFloorsOffice.options.map((option) => {
          return (
            <div
              className={
                Form[PC_DATA.HowManyFloorsOffice.name] === option.value
                  ? " card  pc-card grow-animate pc-card-active"
                  : "card  pc-card grow-animate "
              }
              key={option.name}
              style={{ width: "100%" }}
              onClick={() =>
                handleInputChange({
                  name: PC_DATA.HowManyFloorsOffice.name,
                  value: option.value,
                })
              }
            >
              <div className="card-body">
                {/* <span className="card-title"> */}
                <img
                  src={option.img}
                  className="price-calc-icon sm:float-left"
                />
                <span className="hidden sm:block sm:float-left sm:align-middle mt-[1.4rem] font-medium text-xlg ">
                  {option.name}
                </span>
                {/* </span> */}
              </div>
              <div className="card-footer pc-caption block sm:hidden">
                {option.name}
              </div>
            </div>
          );
        })}
      </div>
    </m.section>
  );
}
function HowManyFloorsListAppartment(props) {
  const { handleInputChange, Form, animate } = props;

  return (
    <m.section {...animate}>
      <h3>{PC_DATA.HowManyFloorsAppartment.question}</h3>
      <div className="grid grid-cols-3     sm:grid-cols-1 gap-4 sm:gap-2 place-items-center place-content-center">
        {PC_DATA.HowManyFloorsAppartment.options.map((option) => {
          return (
            <div
              className={
                Form[PC_DATA.HowManyFloorsAppartment.name] === option.value
                  ? " card  pc-card grow-animate pc-card-active"
                  : "card  pc-card grow-animate "
              }
              key={option.name}
              style={{ width: "100%" }}
              onClick={() =>
                handleInputChange({
                  name: PC_DATA.HowManyFloorsAppartment.name,
                  value: option.value,
                })
              }
            >
              <div className="card-body">
                {/* <span className="card-title"> */}
                <img
                  src={option.img}
                  className="price-calc-icon sm:float-left"
                />
                <span className="hidden sm:block sm:float-left sm:align-middle mt-[1.4rem] font-medium text-xlg ">
                  {option.name}
                </span>
                {/* </span> */}
              </div>
              <div className="card-footer pc-caption block sm:hidden">
                {option.name}
              </div>
            </div>
          );
        })}
      </div>
    </m.section>
  );
}
function HowManyFloorsListBusiness(props) {
  const { handleInputChange, Form, animate } = props;

  return (
    <m.section {...animate}>
      <h3>{PC_DATA.HowManyFloorsBusiness.question}</h3>
      <div className="grid grid-cols-3     sm:grid-cols-1 gap-4 sm:gap-2 place-items-center place-content-center">
        {PC_DATA.HowManyFloorsBusiness.options.map((option) => {
          return (
            <div
              className={
                Form[PC_DATA.HowManyFloorsBusiness.name] === option.value
                  ? " card  pc-card grow-animate pc-card-active"
                  : "card  pc-card grow-animate "
              }
              key={option.name}
              style={{ width: "100%" }}
              onClick={() =>
                handleInputChange({
                  name: PC_DATA.HowManyFloorsBusiness.name,
                  value: option.value,
                })
              }
            >
              <div className="card-body">
                {/* <span className="card-title"> */}
                <img
                  src={option.img}
                  className="price-calc-icon sm:float-left"
                />
                <span className="hidden sm:block sm:float-left sm:align-middle mt-[1.4rem] font-medium text-xlg ">
                  {option.name}
                </span>
                {/* </span> */}
              </div>
              <div className="card-footer pc-caption block sm:hidden">
                {option.name}
              </div>
            </div>
          );
        })}
      </div>
    </m.section>
  );
}
function WindowsAndExterior(props) {
  const { handleSelectChange, Form, nextPriceCard, animate } = props;
  const options = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30,
  ];
  return (
    <m.section {...animate}>
      <h3>{PC_DATA.WindowsAndExterior.question}</h3>
      <div>
        <span className="font-bold">Anzahl Fenster und Außentüren:</span>
        <select
          name={PC_DATA.WindowsAndExterior.name}
          defaultValue={1}
          onChange={handleSelectChange}
          value={Form[PC_DATA.WindowsAndExterior.name]}
          class="bg-gray-50 border  ml-8 border-gray-300 text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500   p-2.5 "
        >
          <option selected>Fenster und Türen</option>

          {options.map((value) => (
            <option value={value}>{value}</option>
          ))}
        </select>

        {/* <button
          type="button"
          class="text-gray-900 bg-white border border-gray-300   font-medium rounded-lg text-sm px-5 py-2.5   ml-2 "
          onClick={nextPriceCard}
        >
          Weiß nicht
        </button> */}
      </div>
    </m.section>
  );
}
function WindowsAndExteriorAppartment(props) {
  const { handleSelectChange, Form, nextPriceCard, animate } = props;
  const options = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30,
  ];
  return (
    <m.section {...animate}>
      <h3>{PC_DATA.WindowsAndExteriorAppartment.question}</h3>
      <div>
        <span className="font-bold">Anzahl Fenster und Außentüren:</span>
        <select
          name={PC_DATA.WindowsAndExteriorAppartment.name}
          defaultValue={1}
          onChange={handleSelectChange}
          value={Form[PC_DATA.WindowsAndExteriorAppartment.name]}
          class="bg-gray-50 border  ml-8 border-gray-300 text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500   p-2.5 "
        >
          <option selected>Fenster und Türen</option>

          {options.map((value) => (
            <option value={value}>{value}</option>
          ))}
        </select>
        {/* <button
          type="button"
          class="text-gray-900 bg-white border border-gray-300   font-medium rounded-lg text-sm px-5 py-2.5   ml-2 "
          onClick={nextPriceCard}
        >
          Weiß nicht
        </button> */}
      </div>
    </m.section>
  );
}
function WindowsAndExteriorBusiness(props) {
  const { handleSelectChange, Form, nextPriceCard, animate } = props;
  const options = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30,
  ];
  return (
    <m.section {...animate}>
      <h3>{PC_DATA.WindowsAndExteriorBusiness.question}</h3>
      <div>
        <span className="font-bold">Anzahl Fenster und Außentüren:</span>
        <select
          name={PC_DATA.WindowsAndExteriorBusiness.name}
          defaultValue={1}
          onChange={handleSelectChange}
          value={Form[PC_DATA.WindowsAndExteriorBusiness.name]}
          class="bg-gray-50 border  ml-8 border-gray-300 text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500   p-2.5 "
        >
          <option selected>Fenster und Türen</option>

          {options.map((value) => (
            <option value={value}>{value}</option>
          ))}
        </select>
        {/* <button
          type="button"
          class="text-gray-900 bg-white border border-gray-300   font-medium rounded-lg text-sm px-5 py-2.5   ml-2 "
          onClick={nextPriceCard}
        >
          Weiß nicht
        </button> */}
      </div>
    </m.section>
  );
}
function WindowsAndExteriorOffice(props) {
  const { handleSelectChange, Form, nextPriceCard, animate } = props;
  const options = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30,
  ];
  return (
    <m.section {...animate}>
      <h3>{PC_DATA.WindowsAndExteriorOffice.question}</h3>
      <div>
        <span className="font-bold">Anzahl Fenster und Außentüren:</span>
        <select
          name={PC_DATA.WindowsAndExteriorOffice.name}
          defaultValue={1}
          onChange={handleSelectChange}
          value={Form[PC_DATA.WindowsAndExteriorOffice.name]}
          class="bg-gray-50 border  ml-8 border-gray-300 text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500   p-2.5 "
        >
          <option selected>Fenster und Türen</option>

          {options.map((value) => (
            <option value={value}>{value}</option>
          ))}
        </select>
        {/* <button
          type="button"
          class="text-gray-900 bg-white border border-gray-300   font-medium rounded-lg text-sm px-5 py-2.5   ml-2 "
          onClick={nextPriceCard}
        >
          Weiß nicht
        </button> */}
      </div>
    </m.section>
  );
}

function Pets(props) {
  const { handleInputChange, Form, animate } = props;

  return (
    <m.section {...animate}>
      <h3>{PC_DATA.Pets.question}</h3>
      <div className="grid grid-cols-2     sm:grid-cols-1 gap-4 sm:gap-2 place-items-center place-content-center">
        {PC_DATA.Pets.options.map((option) => {
          return (
            <div
              className={
                Form[PC_DATA.Pets.name] === option.value
                  ? " card  pc-card grow-animate pc-card-active"
                  : "card  pc-card grow-animate "
              }
              key={option.name}
              style={{ width: "100%" }}
              onClick={() =>
                handleInputChange({
                  name: PC_DATA.Pets.name,
                  value: option.value,
                })
              }
            >
              <div className="card-body">
                {/* <span className="card-title"> */}
                <img
                  src={option.img}
                  className="price-calc-icon sm:float-left"
                />
                <span className="hidden sm:block sm:float-left sm:align-middle mt-[1.4rem] font-medium text-xlg ">
                  {option.name}
                </span>
                {/* </span> */}
              </div>
              <div className="card-footer pc-caption block sm:hidden">
                {option.name}
              </div>
            </div>
          );
        })}
      </div>
    </m.section>
  );
}
function Bedroom(props) {
  const { handleInputChange, Form, animate } = props;

  return (
    <m.section {...animate}>
      <h3>{PC_DATA.Bedroom.question}</h3>
      <div className="grid grid-cols-3     sm:grid-cols-1 gap-4 sm:gap-2 place-items-center place-content-center">
        {PC_DATA.Bedroom.options.map((option) => {
          return (
            <div
              className={
                Form[PC_DATA.Bedroom.name] === option.value
                  ? " card  pc-card grow-animate pc-card-active"
                  : "card  pc-card grow-animate "
              }
              key={option.name}
              style={{ width: "100%" }}
              onClick={() =>
                handleInputChange({
                  name: PC_DATA.Bedroom.name,
                  value: option.value,
                })
              }
            >
              <div className="card-body">
                {/* <span className="card-title"> */}
                <img
                  src={option.img}
                  className="price-calc-icon sm:float-left"
                />
                <span className="hidden sm:block sm:float-left sm:align-middle mt-[1.4rem] font-medium text-xlg ">
                  {option.name}
                </span>
                {/* </span> */}
              </div>
              <div className="card-footer pc-caption block sm:hidden">
                {option.name}
              </div>
            </div>
          );
        })}
      </div>
    </m.section>
  );
}
function AccessViaSmartPhone(props) {
  const { handleInputChange, Form, animate } = props;

  return (
    <m.section {...animate}>
      <h3>{PC_DATA.AccessViaSmartPhone.question}</h3>
      <div className="grid grid-cols-3     sm:grid-cols-1 gap-4 sm:gap-2 place-items-center place-content-center">
        {PC_DATA.AccessViaSmartPhone.options.map((option) => {
          return (
            <div
              className={
                Form[PC_DATA.AccessViaSmartPhone.name] === option.value
                  ? " card  pc-card grow-animate pc-card-active"
                  : "card  pc-card grow-animate "
              }
              key={option.name}
              style={{ width: "100%" }}
              onClick={() =>
                handleInputChange({
                  name: PC_DATA.AccessViaSmartPhone.name,
                  value: option.value,
                })
              }
            >
              <div className="card-body">
                {/* <span className="card-title"> */}
                <img
                  src={option.img}
                  className="price-calc-icon sm:float-left"
                />
                <span className="hidden sm:block sm:float-left sm:align-middle mt-[1.4rem] font-medium text-xlg ">
                  {option.name}
                </span>
                {/* </span> */}
              </div>
              <div className="card-footer pc-caption block sm:hidden">
                {option.name}
              </div>
            </div>
          );
        })}
      </div>
    </m.section>
  );
}
function Extentions(props) {
  const { handleInputChange, Form, setForm, nextPriceCard, animate } = props;
  function pushToCrossSellingArray(item) {
    const arr = Form[PC_DATA.Extentions.name];
    const index = arr.indexOf(item);
    if (index !== -1) {
      arr.splice(index, 1);
    } else {
      arr.push(item);
    }
    setForm({ ...Form, ...{ crossSelling: arr } });
  }
  return (
    <m.section>
      <h3 className="mb-0 pb-0">{PC_DATA.Extentions.question}</h3>
      <p className="mb-6 ">{PC_DATA.Extentions.questionInfo}</p>
      <div className="grid grid-cols-3     sm:grid-cols-1 gap-4 sm:gap-2 place-items-center place-content-center">
        {PC_DATA.Extentions.options.map((option) => {
          return (
            <div
              className={
                Form[PC_DATA.Extentions.name].includes(option.value)
                  ? " card  pc-card grow-animate pc-card-active"
                  : "card  pc-card grow-animate "
              }
              key={option.name}
              style={{ width: "100%" }}
              onClick={() => pushToCrossSellingArray(option.value)}
            >
              <div className="card-body">
                {/* <span className="card-title"> */}
                <img
                  src={option.img}
                  className="price-calc-icon sm:float-left"
                />
                <span className="hidden sm:block sm:float-left sm:align-middle mt-[1.4rem] font-medium text-xlg ">
                  {option.name}
                </span>
                {/* </span> */}
              </div>
              <div className="card-footer pc-caption block sm:hidden">
                {option.name}
              </div>
            </div>
          );
        })}
      </div>
    </m.section>
  );
}

function UserInfoForm(props) {
  const { Form, nextPriceCard, animate } = props;
  const inputClasses =
    " w-full rounded-md  py-2.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 mb-3 ";
  const errorClasses = " !border-2 !border-red-500";
  const extraKeys = {
    cctv: "ungefragt",
    cctv_count: "ungefragt",
    cctv_resolution: "ungefragt",
    cctv_type: "ungefragt",
    securitySystem: "Einbruch",
    fireAlarmAlreadyEquiped: "ungefragt",
    fireAlarmCarryProcess: "ungefragt",
    fireAlarmFloors: "ungefragt",
    fireAlarmRealEstateSize: "ungefragt",
    fireAlarmRequirements: "ungefragt",
    A_B_Test_Source__c: "New Website",

    // crossSelling: [],
  };
  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({ event: "PR_CHECKOUT_REACHED" });
    } else {
      console.log("window.dataLayer not created");
    }
  }, []);
  const [User, setUser] = useState({});
  phone = User.phone;
  const [ErrorsUser, setErrorUser] = useState({
    email: true,
    phone: true,
    firstName: true,
    lastName: true,
    salutation: true,
  });
  const [formSubmitted, setFormSubmittedX] = useState(false);

  async function submitCalculatorForm(e) {
    e.preventDefault();
    console.log("submitCalculatorForm fired", formSubmitted);
    setFormSubmittedX(1);

    if (
      ErrorsUser.email ||
      ErrorsUser.firstName ||
      ErrorsUser.lastName ||
      ErrorsUser.phone ||
      ErrorsUser.salutation
    ) {
      console.log("err");
      return;
    }
    const storedParams = sessionStorage.getItem("queryParams");
    const urlParams = storedParams ? JSON.parse(storedParams) : {};

    await fetch(API_URL + "/checkout", {
      // fetch(`process.env.REACT_APP_API_URL` + "/checkout", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...Form, ...User, ...extraKeys, ...urlParams }),
    }).then((response) => {
      console.log("Response FROM /CHECKOUT", response);
      nextPriceCard();
    });
  }

  function handleInputUserChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setUser({
      ...User,
      ...{
        [name]: value,
      },
    });
    console.log("user is", User);
    console.log("error is", ErrorsUser);
    if (name == "email") {
      let isError = true;
      if (
        String(value)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      ) {
        isError = false;
      }
      setErrorUser({ ...ErrorsUser, email: isError });
    }
    if (name == "phone") {
      let isError = true;
      if (value && value.length > 3) {
        isError = false;
      }
      setErrorUser({ ...ErrorsUser, phone: isError });
    }
    if (name == "firstName") {
      let isError = true;
      if (value && value.length > 1 && value.length <= 30) {
        isError = false;
      }
      setErrorUser({ ...ErrorsUser, firstName: isError });
    }
    if (name == "lastName") {
      let isError = true;
      if (value && value.length > 1 && value.length <= 30) {
        isError = false;
      }
      setErrorUser({ ...ErrorsUser, lastName: isError });
    }
    if (name == "salutation") {
      let isError = true;
      if (value) {
        isError = false;
      }
      setErrorUser({ ...ErrorsUser, salutation: isError });
    }
  }
  return (
    <m.section {...animate}>
      <h3
        style={{
          padding: "0",
        }}
      >
        Wer soll die kostenlose Preiskalkulation erhalten?
      </h3>
      <div className="grid grid-cols-2 sm:grid-cols-1 ">
        <div>
          <div class="flex items-center mb-4">
            <input
              id="gender1"
              type="radio"
              value="herr"
              name="salutation"
              class="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              onChange={handleInputUserChange}
              checked={User.salutation == "herr"}
            />
            <label
              for="gender1"
              className={
                formSubmitted && ErrorsUser.salutation
                  ? "ms-2  font-medium text-lg text-red-500"
                  : "ms-2  font-medium text-lg text-dark-grey"
              }
            >
              Herr
            </label>
            <input
              id="gender2"
              type="radio"
              value="frau"
              name="salutation"
              class="w-6 h-6 ml-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              onChange={handleInputUserChange}
              checked={User.salutation == "frau"}
            />
            <label
              for="gender2"
              className={
                formSubmitted && ErrorsUser.salutation
                  ? "ms-2  font-medium text-lg text-red-500"
                  : "ms-2  font-medium text-lg text-dark-grey"
              }
            >
              Frau
            </label>
          </div>
          <div className="grid grid-cols-2 gap-x-2">
            {" "}
            <input
              type="text"
              name="firstName"
              placeholder="Vorname"
              className={
                formSubmitted && ErrorsUser.firstName
                  ? inputClasses + errorClasses
                  : inputClasses
              }
              onChange={handleInputUserChange}
              value={User.firstName}
            />
            <input
              type="text"
              name="lastName"
              placeholder="Nachname"
              className={
                formSubmitted && ErrorsUser.lastName
                  ? inputClasses + errorClasses
                  : inputClasses
              }
              onChange={handleInputUserChange}
              value={User.lastName}
            />
          </div>
          <input
            type="text"
            name="email"
            placeholder="E-Mail"
            className={
              formSubmitted && ErrorsUser.email
                ? inputClasses + errorClasses
                : inputClasses
            }
            onChange={handleInputUserChange}
            value={User.email}
          />
          <input
            type="number"
            name="phone"
            placeholder="Telefon"
            className={
              formSubmitted && ErrorsUser.phone
                ? inputClasses + errorClasses
                : inputClasses
            }
            onChange={handleInputUserChange}
            value={User.phone}
          />
          <div className="text-xs mt-3 text-grey text-[#777f8e] text-left ">
            <i class="fas fa-lock mr-1"></i>
            Die Sicherheit Ihrer Daten ist uns wichtig! Ihre Daten werden streng
            vertraulich behandelt und nicht an Dritte weitergegeben.
          </div>
          <Buttons
            className="btn-fill mr-[10px] font-bold mt-4 block  "
            themeColor="#1895FF"
            color="#fff"
            size="lg"
            title="Preis berechnen"
            onClick={submitCalculatorForm}
          />
        </div>
        {/* <div className="grid grid-cols-2 d-block d-md-none mt-5">
          <img  src={CHECKOUT1} style={{ maxWidth: "20rem" }} />
          <img
            src={CHECKOUT2}
            className="mt-2 mb-2"
            style={{ maxWidth: "20rem" }}
          />
        </div> */}
        <div className="grid grid-cols-2 sm:grid-cols-1">
          <div className="ml-6 d-none d-md-block">
            <b className="text-xs">Preiskalkulation (Beispiel):</b>
            <img src={CHECKOUT1} />
          </div>
          <div>
            <span className="d-none d-md-block">
              <b className="text-xs">Gefördert durch:</b>
              <img
                src={CHECKOUT2}
                className="p-2  ml-12"
                style={{ height: "100px", display: "block" }}
              />
            </span>
            <div
              className="ml-4  p-2 d-none d-md-block"
              style={{
                backgroundColor: "rgb(242, 242, 242)",
                padding: "0.9rem",
                // maxHeight: "125px",
                minHeight: "124px",
              }}
            >
              <i
                class="fas fa-piggy-bank  block fa-piggy-custom"
                style={{
                  color: "rgb(24, 149, 255)",
                  verticalAlign: "middle",
                  fontSize: "3rem",
                }}
              ></i>
              <p
                className="text-sm"
                style={{ lineHeight: "1.7rem !important" }}
              >
                Jetzt noch für kurze Zeit:{" "}
                <span className="font-bold"> -10% Frühjahrs-Rabatt</span> auf
                unsere Sicherheitstechnik
              </p>
            </div>
          </div>
        </div>
      </div>
    </m.section>
  );
}

function ThankYou(props) {
  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({ event: "THANKYOU_REACHED" });
      window.dataLayer.push({ event: "INTRUSION_LEAD_TIRGGER" });
    } else {
      console.log("window.dataLayer not created");
    }
  }, []);
  return (
    <m.section>
      <div style={{ fontSize: "20px" }} id="INTRUSION_LEAD_TIRGGER">
        <img src={THANK_YOU} height="100px" width="100px" />

        <b className="block mt-4 mb-4 text-dark-grey">Wie geht es weiter?</b>

        <p>
          Ein MeinAlarm24 Sicherheitsberater berechnet aktuell einen ersten
          Preis für Ihr Projekt und wird sich bei offen gebliebenen Fragen unter
          der folgenden Telefonnummer bei Ihnen melden:
        </p>
        <br />
        <b className="text-dark-grey">{phone}</b>
      </div>
    </m.section>
  );
}

export default PriceCalculator;
